<template>
  <div class="d-flex justify-content-center mt-4" @click="() => {this.$router.push({path: '/signup'})}">
    <div class="social-btn d-flex">
      <div class="p-2 ico-envelope"></div>
      <div class="p-2">{{$t("sign_up_with_email")}}</div>
      <div class="ms-auto p-2 ico-right-arrow"></div>
    </div>
  </div>
</template>

<script>
export default {
name: "btn-email"
}
</script>

<style scoped>

</style>